<template>
  <div>
    <div
      v-for="key in Object.keys(timeslots)"
      :key="key"
      class="text-xs flex my-2"
    >
      <div class="w-30">{{ getDay(parseInt(key)) }}</div>
      <div v-for="(slot, index) in timeslots[key]" :key="index">
        <span class="ml-2 bg-gray-200 rounded-lg py-0.5 px-2">
          {{ slot.startTime }} - {{ slot.endTime }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import dateFns from '@/utils/data'

export default {
  name: 'Timeslots',
  props: {
    timeslots: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  created: function () {},
  methods: {
    getDay(d) {
      return dateFns.daysOfWeek.find((day) => day.key === d).value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
