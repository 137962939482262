<template>
  <modal :show="show" size="lg" show-close-icon @close="$emit('close')">
    <template v-slot:header>
      {{ location.name }}
    </template>

    <div class="max-h-60v">
      <img
        :src="location.imageUrl"
        class="rounded-md h-24 object-cover w-full"
      />
      <template
        v-if="activeCardOnLocation && activeCardOnLocation.remaining > 0"
      >
        <p class="text-sm border border-green-600 rounded-lg p-4 mt-4">
          Je hebt een strippenkaart voor deze locatie en nog
          <strong>{{ activeCardOnLocation.remaining }} strippen</strong>
          over
        </p>
      </template>
      <template v-else-if="location.creditcards && location.creditcards.length">
        <div class="flex flex-row space-x-2">
          <div
            v-for="creditcard in location.creditcards"
            :key="creditcard.id"
            class="border border-green-600 rounded-lg p-4 mt-4 flex flex-col flex-grow flex-1"
          >
            <p class="font-semibold text-sm">{{ creditcard.name }}</p>
            <p class="text-xs mt-2 flex-grow" v-html="creditcard.description" />

            <button
              class="button button-primary button-sm w-full truncate mt-4 items-end"
              @click="$emit('buy-creditcard', creditcard, location)"
            >
              Koop nu
            </button>
          </div>
        </div>
      </template>

      <div class="text-sm mt-4" v-html="descriptionWithBr" />

      <h2>Adres</h2>
      <p class="text-sm">
        {{ location.address.street }} {{ location.address.houseNumber }}
      </p>
      <p class="text-sm">
        {{ location.address.postalCode }} {{ location.address.city }}
      </p>

      <h2>Beschikbare tijden</h2>
      <timeslots :timeslots="timeslots" />

      <template v-if="location.extra_info">
        <div v-for="(item, index) in location.extra_info" :key="index">
          <h2>{{ item.title }}</h2>
          <div class="text-sm table" v-html="item.content" />
        </div>
      </template>
    </div>

    <template v-slot:footer>
      <button
        class="button button-primary w-full"
        @click="$emit('book-location', location)"
      >
        Direct boeken
      </button>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import Timeslots from './Timeslots.vue'
import { formatSlots } from '@/utils/booking'
import { PRICING_PARAM_MEMBER } from '@/utils/constants'
import { CREDITCARD_GET_ALL_OF_USER_ON_LOCATION } from '../../store/actions/creditcard'
import { mapState } from 'vuex'

export default {
  name: 'LocationDetails',
  components: { Modal, Timeslots },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    location: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      activeCardOnLocation: (state) => state.creditcard.activeCardOnLocation
    }),
    timeslots: function () {
      return this.location.timeslots ? formatSlots(this.location.timeslots) : []
    },
    hasMemberPrice: function () {
      return this.location?.pricing?.params.find(
        (param) => param.key === PRICING_PARAM_MEMBER
      )
    },
    lowestMemberPrice: function () {
      return this.location?.pricing?.data?.reduce(
        (res, item) =>
          item.member && (!res.amount || item.price < res.amount)
            ? { amount: item.price, duration: item.duration }
            : res,
        {}
      )
    },
    lowestNonMemberPrice: function () {
      return this.location?.pricing?.data?.reduce(
        (res, item) =>
          !item.member && (!res.amount || item.price < res.amount)
            ? { amount: item.price, duration: item.duration }
            : res,
        {}
      )
    },
    descriptionWithBr() {
      return (
        this.location?.description?.replace(/(?:\r\n|\r|\n)/g, '<br />') ??
        undefined
      )
    }
  },
  mounted: function () {
    this.$store.dispatch(CREDITCARD_GET_ALL_OF_USER_ON_LOCATION, {
      locationId: this.location.id
    })
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

.table {
  table {
    padding: 0.5rem;
    font-size: 12px !important;
  }
}
</style>
