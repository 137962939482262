<template>
  <div>
    <AppBar title="Locatie huren" :show-back-button="true" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <template v-if="event.commercialLocation">
        <h1>Controleer je boeking</h1>
        <div>
          <h2>Locatie:</h2>
          <div class="text-sm">
            {{ event.commercialLocation.name }}
          </div>
          <h2>Wanneer:</h2>
          <div class="text-sm">
            {{ date }}
          </div>
          <div class="text-sm">
            {{ time }}
          </div>
          <h2>Kosten:</h2>
          <div class="text-sm">
            {{ $n(cost, 'currency') }}
          </div>
        </div>
        <template>
          <h2>Telefoonnummer</h2>
          <p class="text-sm">
            Vul je telefoon nummer in of pas deze aan zodat we contact kunnen
            opnemen mocht dat nodig zijn
          </p>
          <div class="input-container">
            <input-field
              id="phoneNumber"
              v-model="event.phoneNumber"
              class="mt-2 mb-2"
              default-value="Telefoon nummer"
              @input="storePhoneNumber"
            />
            <i
              v-if="event.phoneNumber && !phoneNumberError"
              class="inline-icon fal fa-check"
            />
          </div>
          <div v-if="phoneNumberError" class="alert alert-danger mt-3">
            Dit geen correct telefoonnummer
          </div>
        </template>

        <button
          :disabled="buttonDisabled"
          class="button button-primary w-full mt-5"
          @click="placeBooking"
        >
          Nu boeken
        </button>
      </template>
      <template v-else>
        <h1 class="page-title mt-4 mb-5">Er gaat iets fout met je boeking</h1>
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import InputField from '@/components/InputField'
import { mapState } from 'vuex'
import moment from 'moment'
import {
  PARTICIPANT_OWN,
  PARTICIPANT_UPDATE_PHONENUMBER
} from '@/store/actions/participant'
import { EVENT_ITEM_SET_INFO } from '@/store/actions/event'
import { LOCATION_BOOK } from '@/store/actions/location'

export default {
  name: 'LocationsBookConfirm',
  components: { AppBar, SpinnerLoader, InputField },
  data() {
    return {
      loading: false,
      event: {
        phoneNumber: null
      },
      startTime: undefined,
      endTime: undefined,
      phoneNumberError: false,
      buttonDisabled: false
    }
  },
  computed: {
    ...mapState({
      eventItem: (state) => state.event.eventItem,
      profile: (state) => state.participant.ownProfile
    }),
    date: function () {
      return `${moment(this.eventItem.startDate).format('dddd D MMMM YYYY')}`
    },
    time: function () {
      return `${moment(this.eventItem.startTime).format('HH:mm')} tot ${moment(
        this.eventItem.endTime
      ).format('HH:mm')}`
    },
    cost: function () {
      const start = moment(this.eventItem.startTime)
      const end = moment(this.eventItem.endTime)
      const duration = moment.duration(end.diff(start)).as('minutes')
      return (
        this.eventItem.cost *
        (duration / this.eventItem.commercialLocation.slotDuration[0])
      )
    }
  },
  watch: {
    profile(p) {
      if (p && p.phoneNumber && !this.event.phoneNumber) {
        this.event.phoneNumber = p.phoneNumber
      }
    }
  },
  created: function () {
    this.event = { ...this.eventItem }
    this.$store.dispatch(PARTICIPANT_OWN)
    if (this.profile && this.profile.phoneNumber) {
      this.event.phoneNumber = this.profile.phoneNumber
    }
  },
  methods: {
    placeBooking() {
      this.$store.dispatch(LOCATION_BOOK, this.event)
      this.$router.push('/locations/book/activity')
    },
    isValid() {
      const regex = RegExp(
        /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
      )
      return regex.test(this.event.phoneNumber)
    },
    storePhoneNumber() {
      setTimeout(() => {
        if (this.isValid()) {
          this.phoneNumberError = false
          this.buttonDisabled = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.event.phoneNumber
          })
          this.$store.commit(EVENT_ITEM_SET_INFO, this.event)
        } else {
          this.phoneNumberError = true
          this.buttonDisabled = true
        }
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.input-container {
  position: relative;
}
.inline-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: $ipGreen;
}
</style>
