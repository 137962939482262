<template>
  <div class="has-tab-bar">
    <AppBar
      title="Locaties"
      :show-back-button="false"
      :show-profile-button="true"
    />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>Kies een locatie om te huren</h1>
      <div class="flex space-x-4">
        <dropdown
          :selected="selectedCity"
          :disabled="false"
          :items="listOfCities"
          empty-option="Alle steden"
          theme="rounded"
          class="mt-4 mb-8 flex-1"
          @on-select="(val) => selectCity(val)"
        />
        <dropdown
          v-if="listOfActivities.length > 0"
          :selected="selectedActivity"
          :disabled="false"
          :items="listOfActivities"
          empty-option="Alle sporten"
          theme="rounded"
          class="mt-4 mb-8 flex-1"
          @on-select="(val) => selectActivity(val)"
        />
      </div>
      <template v-if="filteredList.length === 0 && !loading">
        <p class="text-center">
          Er zijn geen locaties gevonden voor deze sport in deze stad.
        </p>
      </template>
      <template v-else>
        <div
          v-for="location in filteredList"
          :key="location.id"
          class="flex border border-gray-300 rounded-md p-2 hover:cursor-pointer hover:bg-gray-100 my-3"
          @click="showDetails(location)"
        >
          <div class="w-24 h-24">
            <img
              v-if="location.imageUrl"
              class="rounded-md object-cover w-full h-full"
              :src="location.imageUrl"
            />
          </div>
          <div class="flex-1 ml-4 flex flex-col justify-between">
            <h2 class="mt-0 mb-0">
              {{ location.name }}
            </h2>
            <!-- 
            <div class="flex items-center">
              <div class="flex flex-1">
                <div
                  v-for="activity in location.activities"
                  :key="activity"
                  class="mx-1"
                >
                  <RoundIcon
                    alt="Sport"
                    :size="24"
                    :border-size="0"
                    background-color="#009b6d"
                    icon-color="#fff"
                    :icon-class="activityIcons[activity]"
                  />
                </div>
              </div>
            </div>
            -->

            <div class="flex text-sm text-gray-600 items-center">
              <div class="flex-1">
                {{ location.address.city }}
              </div>
              <div
                v-if="
                  location.lowestNonMemberPrice &&
                  Object.keys(location.lowestNonMemberPrice).length
                "
                class="text-xs"
              >
                v.a.
                {{ $n(location.lowestNonMemberPrice.amount, 'currency') }} /
                {{ location.lowestNonMemberPrice.duration }} min
              </div>
            </div>
            <div class="text-right flex justify-end flex-wrap gap-2 mt-2">
              <div
                v-if="location.hasMemberPrice"
                class="flex-shrink h-5 py-0.5 px-2 inline-block rounded-lg bg-green-600 text-white text-xs uppercase"
              >
                leden: {{ $n(location.lowestMemberPrice.amount, 'currency') }}
              </div>
              <div
                v-if="location.creditcards && location.creditcards.length"
                class="truncate ... max-w-13 sm:max-w-xs h-5 py-0.5 px-2 inline-block bg-green-600 rounded-lg text-white text-xs uppercase"
              >
                korting met strippenkaart
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <location-details
      v-if="selectedLocation"
      :location="selectedLocation"
      :show="!!selectedLocation"
      @book-location="bookLocation"
      @close="closeModal"
      @buy-creditcard="buyCreditcard"
    />
    <modal :show="showLoginModal">
      <template v-slot:header>Heb je al een account?</template>
      Eerst inloggen
    </modal>
    <TabBar selected="locations" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import LocationDetails from '@/views/locations/LocationDetails'
import { mapState, mapGetters } from 'vuex'
import { LOCATION_GET_COMMERCIAL } from '@/store/actions/location'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import Modal from '@/components/Modal'
import Dropdown from '@/components/Dropdown'
import SpinnerLoader from '../../components/SpinnerLoader.vue'

export default {
  name: 'LocationsOverview',
  components: {
    AppBar,
    Dropdown,
    TabBar,
    LocationDetails,
    Modal,
    SpinnerLoader
  },
  data() {
    return {
      selectedLocation: null,
      activityIcons: {},
      formattedSlots: undefined,
      showLoginModal: false,
      selectedCity: undefined,
      selectedActivity: undefined,
      cityQuery: this.$route.query.c,
      basePath: this.$route.path.substring(
        0,
        this.$route.path.indexOf('/', 1) > 0
          ? this.$route.path.indexOf('/', 1)
          : this.$route.path.length
      )
      // filteredList: []
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState({
      status: (state) => state.location.status,
      locations: (state) => state.location.commercial,
      activities: (state) => state.activity.activityList
    }),
    locationInUrl: function () {
      return this.$route.params.location
    },
    locationWatchable() {
      return this.locations, this.locationInUrl, Date.now()
    },
    listOfCities: function () {
      // sort alphabetically
      return this.locations
        .reduce(
          (list, location) => [
            ...list,
            ...(!list.find(
              (item) => item.key === location.address.city.trim().toLowerCase()
            ) && location.address.city
              ? [
                  {
                    key: location.address.city.toLowerCase(),
                    value:
                      location.address.city.charAt(0).toUpperCase() +
                      location.address.city.slice(1).toLowerCase()
                  }
                ]
              : [])
          ],
          []
        )
        .sort((a, b) => a.value.localeCompare(b.value))
    },
    listOfActivities: function () {
      return (
        this.locations.reduce((list, location) => {
          location.activities?.forEach((activity) => {
            if (list.length === 0 || !list.find((l) => l.id === activity)) {
              const act = this.activities.find((a) => a.id === activity)
              if (act) {
                list.push({
                  id: act.id,
                  key: act.slug,
                  value: act.name
                })
              }
            }
          })
          return list.sort((a, b) => a.value.localeCompare(b.value))
        }, []) || []
      )
    },
    filteredList: function () {
      const unfiltered = [...this.locations]
      const filtered = [
        ...unfiltered.filter((location) =>
          this.selectedCity
            ? location.address.city.trim().toLowerCase() ===
              this.selectedCity.toLowerCase()
            : true
        )
      ]
      const list = filtered.length || this.selectedCity ? filtered : unfiltered
      return list.sort(
        (a, b) => (b.creditcards?.length || 0) - (a.creditcards?.length || 0)
      )
    },
    loading: function () {
      return this.status === 'loading'
    }
  },
  metaInfo() {
    return {
      title: `Boek een locatie ${
        this.selectedCity
          ? `in ${
              this.selectedCity.charAt(0).toUpperCase() +
              this.selectedCity.slice(1)
            }`
          : ''
      } | Inviplay`
    }
  },
  watch: {
    activities(activities) {
      if (activities.length) {
        this.activityIcons =
          activities &&
          activities.reduce(
            (list, act) => ({ ...list, [act.id]: act.icon }),
            {}
          )
      }
    },
    locationWatchable() {
      if (this.locations.length && this.locationInUrl) {
        this.selectedLocation = this.locations.find(
          (location) => location.slug === this.locationInUrl
        )
      } else {
        this.selectedLocation = null
      }
    },
    '$route.query.c'(q) {
      this.cityQuery = q
      this.selectedCity = q
    }
  },
  created: function () {
    this.$store.dispatch(LOCATION_GET_COMMERCIAL)
    this.$store.dispatch(ACTIVITY_LIST)
    if (this.cityQuery) {
      this.selectedCity = this.cityQuery
    }
  },
  mounted: function () {},
  methods: {
    showDetails(location) {
      const queryString = this.createQueryString(this.$route.query)
      this.$router.push(
        `${this.basePath}/${location.slug}${
          this.cityQuery ? `?c=${this.cityQuery}` : ''
        }${queryString ? `&${queryString}` : ''}`
      )
    },
    closeModal() {
      const queryString = this.createQueryString(this.$route.query)
      this.$router.push(
        `${this.$route.path.substring(0, this.$route.path.lastIndexOf('/'))}${
          this.cityQuery ? `?c=${this.cityQuery}` : ''
        }${queryString ? `&${queryString}` : ''}`
      )
    },
    bookLocation(location) {
      this.$router.push({
        path: `${this.basePath}/${location.slug}/book`,
        query: this.createQueryString(this.$route.query)
      })
    },
    buyCreditcard(creditcard, location) {
      this.$router.push(
        `/cards/${creditcard.id}/purchase?origin=${this.basePath}/${location.slug}/book`
      )
    },
    selectCity(city) {
      if (city) {
        const queryString = this.createQueryString(this.$route.query)
        this.$router.push(
          `${this.basePath}${
            this.locationInUrl ? `/${this.locationInUrl}` : ''
          }?c=${city}${queryString ? `&${queryString}` : ''}`
        )
      } else {
        const queryString = this.createQueryString(this.$route.query)
        this.$router.push(
          `${this.basePath}${
            this.locationInUrl ? `/${this.locationInUrl}` : ''
          }${queryString ? `?${queryString}` : ''}`
        )
      }
    },
    selectActivity(val) {
      this.selectedActivity = val
    },
    createQueryString(query) {
      delete query.c
      return Object.keys(query)
        .map((key) => `${key}=${query[key]}`)
        .join('&')
    }
  }
}
</script>

<style lang="scss" scoped></style>
