export const formatSlots = (slots) => {
  return slots.reduce(
    (obj, slot) => ({
      ...obj,
      [slot.day]: [
        ...(obj[slot.day] ? obj[slot.day] : []),
        {
          startTime: slot.startTime,
          endTime: slot.endTime
        }
      ]
    }),
    {}
  )
}
