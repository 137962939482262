<template>
  <div>
    <AppBar
      title="Locatie huren"
      :show-back-button="true"
      :show-profile-button="false"
      :back-button-path="
        event.commercialLocation &&
        event.commercialLocation.sports &&
        event.commercialLocation.sports.length < 2
          ? '/locations/'
          : null
      "
    />
    <div class="container">
      <h1 class="page-title mt-4 mb-5">Wat voor organisator ben je?</h1>
      <ItemList
        v-if="formattedEventTypeList"
        :items="formattedEventTypeList"
        :selected-id="event.eventTypeID"
        @select-item="selectEventType"
      />
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import ItemList from '@/components/ItemList'
import { mapState } from 'vuex'
import { EVENT_TYPE_LIST } from '@/store/actions/event_type'
import { EVENT_ITEM_SET_EVENT_TYPE } from '@/store/actions/event'
import data from '@/utils/data'

export default {
  name: 'LocationsBookType',
  components: { AppBar, ItemList },
  data() {
    return {
      event: {
        eventTypeID: null
      },
      formattedEventTypeList: []
    }
  },
  computed: {
    ...mapState({
      eventTypeList: (state) => state.eventTypes.eventTypeList,
      eventItem: (state) => state.event.eventItem
    })
  },
  watch: {
    eventTypeList(list) {
      this.formattedEventTypeList = list.map((item) => ({
        ...item,
        name: data.formatEventTypeItems(item.name)
      }))
    }
  },
  created: function () {
    this.event = { ...this.eventItem }
    if (!this.event.commercialLocation) {
      this.$router.push('/locations')
    }
    this.$store.dispatch(EVENT_TYPE_LIST)
  },
  methods: {
    selectEventType: function (eventTypeId) {
      const vm = this
      this.$store.commit(EVENT_ITEM_SET_EVENT_TYPE, eventTypeId)
      setTimeout(function () {
        vm.$router.push('/locations/book/date')
      }, 250)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
