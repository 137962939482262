<template>
  <div>
    <AppBar
      title="Locatie huren"
      :show-back-button="true"
      :show-profile-button="false"
    />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <h1 class="page-title mt-4 mb-5">Wat ga je doen?</h1>
      <ItemList
        v-if="filteredSportsList"
        :items="filteredSportsList"
        :selected-id="event.activityID"
        @select-item="selectActivity"
      />
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import ItemList from '@/components/ItemList'
import { mapState } from 'vuex'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_ITEM_SET_ACTIVITY } from '@/store/actions/event'

export default {
  name: 'LocationsBookSport',
  components: { AppBar, SpinnerLoader, ItemList },
  data() {
    return {
      event: {
        activityID: null
      },
      filteredSportsList: []
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.location.loading,
      activityList: (state) => state.activity.activityList,
      eventItem: (state) => state.event.eventItem
    })
  },
  watch: {
    activityList(list) {
      if (
        this.eventItem.commercialLocation &&
        this.eventItem.commercialLocation.sports
      ) {
        this.filteredSportsList = this.eventItem.commercialLocation.sports.reduce(
          (filteredList, sport) => [
            ...filteredList,
            {
              ...list.find((l) => l.id === sport)
            }
          ],
          []
        )
      }
    },
    filteredSportsList(list) {
      if (list && list.length === 1) {
        this.$store.commit(EVENT_ITEM_SET_ACTIVITY, list[0].id)
        this.$router.push('/locations/book/date')
      }
    }
  },
  created: function () {
    this.event = { ...this.eventItem }
    if (!this.event.commercialLocation) {
      this.$router.push('/locations')
    }
    this.$store.dispatch(ACTIVITY_LIST)
  },
  mounted: function () {},
  methods: {
    selectActivity: function (id) {
      const vm = this
      this.$store.commit(EVENT_ITEM_SET_ACTIVITY, id)
      setTimeout(function () {
        vm.$router.push('/locations/book/date')
      }, 250)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
