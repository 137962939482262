<template>
  <div>
    <app-bar
      title="Locatie boeken"
      :show-back-button="true"
      :show-profile-button="false"
      back-button-path="/locations"
    />
    <spinner-loader v-if="isLoading" />
    <template v-if="selectedLocation">
      <div class="container pb-28">
        <h1>{{ selectedLocation.name }}</h1>
        <template v-if="availabilityMessage">
          <div class="alert alert-warning mt-5 text-sm">
            {{ availabilityMessage }}
          </div>
        </template>
        <template v-if="maxPeriodBeforeBooking">
          <div
            class="border border-gray-100 p-4 rounded-md text-gray-500 bg-gray-100 mt-5 text-sm"
          >
            Deze locatie kan maximaal {{ maxPeriodBeforeBooking }} van te voren
            geboekt worden.
          </div>
        </template>

        <template>
          <h2>Kies een dag</h2>
          <div class="relative">
            <datetime
              v-model="booking.date"
              placeholder="Datum"
              input-class="w-100 h-100 text-left text-sm disabled:bg-white pl-6"
              class="theme-green border h-11 rounded-lg p-1 px-4 border-gray-600"
              :min-datetime="today"
              :phrases="{ ok: 'Kies', cancel: 'Annuleer' }"
              @input="(val) => setParam('date', val)"
            />
            <icon-base class="absolute left-3 top-3 border-gray-600" size="20">
              <icon-calendar />
            </icon-base>
          </div>
        </template>
        <template v-if="dayIsAvailable === false">
          <div class="alert alert-warning mt-5 text-sm">
            Voor deze dag zijn geen tijden beschikbaar
          </div>
        </template>

        <template v-if="booking.date && dayIsAvailable">
          <div v-if="availability.availableTimes">
            <h2>Kies een tijd</h2>
            <div class="flex gap-2 flex-wrap">
              <div
                v-for="(obj, index) in availability.availableTimes"
                :key="index"
                class="border w-16 text-center py-2 rounded-md cursor-pointer hover:bg-gray-100"
                :class="{
                  'border-2 border-green-600 bg-green-100 text-green-900 hover:bg-green-100':
                    obj.time === booking.time
                }"
                @click="setParam('time', obj.time)"
              >
                <span class="text-sm">{{ obj.time }}</span>
              </div>
            </div>
          </div>
          <div v-if="booking.time">
            <h2>Kies een duur</h2>
            <div class="flex gap-2 flex-wrap">
              <div
                v-for="(duration, index) in durationOptions"
                :key="index"
                class="border px-4 py-2 rounded-md cursor-pointer hover:bg-gray-100"
                :class="[
                  {
                    'border-2 border-green-600 bg-green-100 text-green-900 hover:bg-green-100':
                      duration.key === booking.duration
                  },
                  {
                    'border bg-gray-100 text-gray-500 cursor-not-allowed':
                      duration.available === false
                  }
                ]"
                @click="setParam('duration', duration.key)"
              >
                <span class="text-sm">{{ duration.value }}</span>
              </div>
            </div>
          </div>
          <div v-if="!timeIsAvailable" class="alert alert-warning">
            Deze tijd is niet beschikbaar, kies een andere tijd of duur
          </div>
        </template>

        <template v-if="priceParams.length && booking.date && booking.time">
          <div v-for="(param, index) in priceParams" :key="index">
            <template v-if="param.type === 'list' && param.key !== 'duration'">
              <h2>{{ param.title }}</h2>
              <dropdown
                :items="param.items"
                empty-option="Maak een keuze..."
                @on-select="(val) => setParam(param.key, val)"
              />
            </template>
            <template v-else-if="param.type === 'boolean'">
              <div class="flex">
                <h2 class="flex-1">{{ param.title }}</h2>
                <toggle
                  :value="booking[param.key]"
                  @toggle="(val) => setParam(param.key, val)"
                />
              </div>
              <div v-if="param.description" class="text-xs">
                {{ param.description }}
              </div>
            </template>
          </div>
        </template>
      </div>
      <div class="border-t border-gray-300 bottom-0 fixed w-full h-20 bg-white">
        <div
          class="flex p-4 max-w-screen-sm pl-6 pr-6 my-0 mx-auto items-center"
        >
          <div class="flex-1">
            <template v-if="formValid">
              <div
                v-if="
                  activeCardOnLocation && activeCardOnLocation.remaining > 0
                "
              >
                <span class="mr-2">Prijs:</span>
                <span class="font-semibold">1 strip</span>
              </div>
              <div
                v-else-if="calculatedPrice || calculatedPrice === 0"
                class="flex"
              >
                <span class="mr-2">Prijs:</span>
                <template
                  v-if="discount && discount.value && amountBeforeDiscount"
                >
                  <span class="line-through mr-2">
                    {{ $n(amountBeforeDiscount, 'currency') }}
                  </span>
                  <span class="font-semibold text-green-600 mr-2">
                    {{ $n(calculatedPrice, 'currency') }}
                  </span>
                  <span @click="showDiscountModal = true">
                    <icon-base size="24">
                      <icon-info :stroke-width="2" />
                    </icon-base>
                  </span>
                </template>
                <template v-else>
                  <span class="font-semibold">
                    {{ $n(calculatedPrice, 'currency') }}
                  </span>
                </template>
              </div>
            </template>
          </div>
          <button
            class="button button-primary"
            :disabled="!formValid || isLoading"
            @click="showReview = true"
          >
            Boeken
          </button>
        </div>
      </div>
      <modal size="lg" :show="showReview" @close="showReview = false">
        <template v-slot:header>Controleer je boeking</template>
        <p class="font-semibold mt-4">Locatie</p>
        <p class="text-sm">{{ selectedLocation.name }}</p>
        <p class="font-semibold mt-4">Datum en tijd</p>
        <p class="text-sm">{{ dateAndTime }}</p>
        <div v-for="(param, index) in priceParams" :key="index">
          <p class="font-semibold mt-4">{{ param.title }}</p>
          <p class="text-sm">
            {{
              typeof booking[param.key] === 'boolean'
                ? booking[param.key]
                  ? 'Ja'
                  : 'Nee'
                : param.key === 'duration'
                ? booking[param.key] + ' minuten'
                : booking[param.key]
            }}
          </p>
        </div>
        <p class="font-semibold mt-4">Prijs</p>
        <template
          v-if="activeCardOnLocation && activeCardOnLocation.remaining > 0"
        >
          <p class="text-sm">1 strip</p>
          <p class="text-xs text-gray-500 mt-1">
            Nadat wij je boeking hebben bevestigd per mail wordt er een strip
            van je rittenkaart afgehaald. Je hebt dan nog
            {{ activeCardOnLocation.remaining - 1 }} strip(pen) over.
          </p>
        </template>
        <template v-else>
          <p class="text-sm">{{ $n(calculatedPrice, 'currency') }}</p>
        </template>
        <p class="font-semibold mt-4">Telefoonnummer</p>
        <p class="text-sm">
          Vul je telefoonnummer is zodat we contact kunnen opnemen mocht dat
          nodig zijn
        </p>
        <div class="relative">
          <input-field
            id="phoneNumber"
            v-model="phoneNumber"
            class="mt-2 mb-2"
            default-value="Telefoon nummer"
            @input="storePhoneNumber"
          />
          <i
            v-if="phoneNumber && !phoneNumberError"
            class="absolute top-4 right-4 text-green-600 fal fa-check"
          />
          <div v-if="phoneNumberError" class="text-red-800 mt-3 text-sm">
            Dit geen correct telefoonnummer
          </div>
        </div>
        <template v-slot:footer>
          <button
            class="button button-primary w-full"
            :disabled="!phoneNumber || phoneNumberError"
            @click="bookLocation"
          >
            Boeken
          </button>
        </template>
      </modal>
      <modal :show="showConfirmation" @close="backToOverview">
        <template v-slot:header>Gelukt!</template>
        <p class="text-center text-4xl mb-6">🎉</p>
        <p class="text-sm">
          We hebben je boeking ontvangen. Je ontvangt zometeen een e-mail met de
          bevestiging van je boeking{{
            activeCardOnLocation && activeCardOnLocation.remaining > 0
              ? '.'
              : ' en een link om te betalen.'
          }}
          Heb je niks ontvangen? Check dan ook even je spamfolder of neem
          contact op via contact@inviplay.nl
        </p>
      </modal>
      <modal :show="showError" @close="showError = false">
        <template v-slot:header>Oeps!</template>
        <p class="text-center text-4xl mb-6">👀</p>
        <p class="text-sm">
          Er is iets fout gegaan. Probeer het nog een keer of neem contact op
          via contact@inviplay.nl
        </p>
      </modal>
      <modal
        v-if="discount && discount.value && discount.type"
        :show="showDiscountModal"
        @close="showDiscountModal = false"
      >
        <template v-slot:header>Korting!</template>
        <p class="text-center text-4xl mb-6">🏆</p>
        <p class="text-sm">
          Voor deze boeking krijg je een korting van
          <strong>
            {{
              discount.type === 'percentage'
                ? `${discount.value}%`
                : $n(discount.value / 100, 'currency')
            }}
          </strong>
          .
        </p>
      </modal>
      <modal
        v-if="!isLoading"
        :show="showFeedbackModal"
        :show-close-icon="true"
        @close="
          () => {
            $router.push($route.path)
          }
        "
      >
        <template v-slot:header>
          {{ paymentIsSuccess ? 'Gelukt' : 'Oeps' }}
        </template>
        <template v-if="action === 'payment'">
          <p v-if="paymentIsSuccess">
            Je betaling is ontvangen en je kunt je strippenkaart nu gebruiken om
            te boeken
          </p>
          <p v-else>Er is iets fout gegaan</p>
        </template>
      </modal>
    </template>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import {
  LOCATION_BOOK_COMMERCIAL,
  LOCATION_GET_COMMERCIAL_AVAILABILITY,
  LOCATION_GET_COMMERCIAL_BY_SLUG
} from '../../store/actions/location'
import Dropdown from '@/components/Dropdown'
import InputField from '@/components/InputField'
import IconBase from '@/components/IconBase'
import IconCalendar from '@/components/icons/IconCalendar'
import IconInfo from '@/components/icons/IconInfo'
import Toggle from '../../components/Toggle.vue'
import { DateTime, Interval } from 'luxon'
import Modal from '@/components/Modal'
import {
  PARTICIPANT_OWN,
  PARTICIPANT_UPDATE_PHONENUMBER
} from '../../store/actions/participant'
import { mapState } from 'vuex'
import { PAYMENT_GET_STATUS_BY_ID } from '../../store/actions/payment'
import { CREDITCARD_GET_ALL_OF_USER_ON_LOCATION } from '../../store/actions/creditcard'
import api from '@/utils/api'

export default {
  name: 'NewBooking',
  components: {
    AppBar,
    SpinnerLoader,
    IconBase,
    IconCalendar,
    IconInfo,
    Dropdown,
    Toggle,
    Modal,
    InputField
  },
  data() {
    return {
      selectedLocation: null,
      locationSlug: this.$route.params.location,
      booking: {
        date: new Date().toISOString()
      },
      timeIsAvailable: true,
      today: new Date().toISOString(),
      minBeginTime: undefined,
      maxBeginTime: undefined,
      minEndTime: undefined,
      maxEndTime: undefined,
      maxHoursBeforeBooking: undefined,
      calculatedPrice: 0,
      amountBeforeDiscount: 0,
      showReview: false,
      showConfirmation: false,
      phoneNumberError: false,
      phoneNumber: undefined,
      buttonDisabled: false,
      showError: false,
      paymentId: this.$route.query.pid,
      showFeedbackModal: false,
      discount: undefined,
      showDiscountModal: false,
      priceLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: (state) => state.participant.ownProfile,
      paymentStatus: (state) => state.payment.status,
      activeCardOnLocation: (state) => state.creditcard.activeCardOnLocation,
      availability: (state) => state.location.commercialAvailability,
      stateLoading: (state) =>
        state.participant.status === 'loading' ||
        state.payment.loading ||
        state.creditcard.loading ||
        state.location.status === 'loading'
    }),
    isLoading: function () {
      return this.stateLoading || this.priceLoading
    },
    action: function () {
      return this.$route.query.action
    },
    dateAndTime: function () {
      const d = DateTime.fromISO(this.booking?.date).toLocaleString(
        DateTime.DATE_FULL
      )
      const t = DateTime.fromISO(this.booking?.time)
      return `${d} van ${t.toFormat('T')} - ${t
        .plus({
          minutes: this.booking?.duration
        })
        .toFormat('T')}`
    },
    durationOptions: function () {
      return this.booking.time
        ? this.availability?.availableTimes
            ?.find((obj) => obj.time === this.booking.time)
            .durationOptions.map((duration) => ({
              key: duration,
              value:
                duration < 60
                  ? `${duration} minuten`
                  : duration === 90
                  ? 'anderhalf uur'
                  : `${duration / 60} uur`
            }))
        : []
    },
    priceParams: function () {
      return (
        (this.booking.date &&
          this.booking.time &&
          this.selectedLocation?.pricing?.params?.reduce(
            (params, item) => [
              ...params,
              ...(item.type !== 'calculated'
                ? [
                    {
                      ...item,
                      items: this.selectedLocation?.pricing?.data?.reduce(
                        (pricingData, d) => [
                          ...pricingData,
                          ...(pricingData.find(
                            (pd) => pd.key === d[item.key]
                          ) ||
                          (d.time_frame &&
                            !this.isInTimeFrame(
                              this.booking.date,
                              this.booking.time,
                              d.time_frame
                            ))
                            ? []
                            : [{ key: d[item.key], value: d[item.key] }])
                        ],
                        []
                      )
                    }
                  ]
                : [...(params.value === item.value ? [] : [{ ...item }])])
            ],
            []
          )) ||
        []
      )
    },
    paymentIsSuccess: function () {
      return this.paymentId && this.paymentStatus
        ? this.paymentStatus[this.paymentId] === 'paid' ||
            this.paymentStatus[this.paymentId] === 'completed'
        : false
    },
    formValid: function () {
      return (
        ((this.calculatedPrice ||
          this.calculatedPrice === 0 ||
          this.activeCardOnLocation?.remaining > 0) &&
          !!this.booking?.date &&
          !!this.booking?.time &&
          !!this.booking?.duration &&
          this.timeIsAvailable) ||
        false
      )
    },
    maxPeriodBeforeBooking: function () {
      return this.maxHoursBeforeBooking
        ? this.maxHoursBeforeBooking > 48
          ? this.maxHoursBeforeBooking / 24 + ' dagen'
          : `${this.maxHoursBeforeBooking} uur`
        : undefined
    },
    dayIsAvailable: function () {
      return !!this.availability.availableTimes?.length
    },
    availabilityMessage: function () {
      return this.selectedLocation?.settings?.availability_message
    }
  },
  watch: {
    action(action) {
      this.showFeedbackModal = action ? true : false
    },
    booking(params) {
      if (params.date && params.time && params.duration) {
        this.getPrice(params)
      }
    },
    'booking.time'() {
      if (
        this.booking.duration &&
        !this.durationOptions
          .map((item) => item.key)
          .includes(this.booking.duration)
      ) {
        this.booking.duration = null
      }
    },
    profile(p) {
      if (p && p.phoneNumber && !this.phoneNumber) {
        this.phoneNumber = p.phoneNumber
      }
    },
    selectedLocation(location) {
      this.$store.dispatch(CREDITCARD_GET_ALL_OF_USER_ON_LOCATION, {
        locationId: location.id
      })
      this.maxHoursBeforeBooking =
        location.settings?.max_hours_before_booking ?? 0
    }
  },
  created: async function () {
    if (this.locationSlug) {
      this.selectedLocation = await this.$store.dispatch(
        LOCATION_GET_COMMERCIAL_BY_SLUG,
        this.locationSlug
      )
    }
    if (!this.phoneNumber) {
      this.$store.dispatch(PARTICIPANT_OWN)
    }
    if (this.action) {
      this.showFeedbackModal = true
      if (this.action === 'payment' && this.paymentId) {
        // get payment status of this user
        this.$store.dispatch(PAYMENT_GET_STATUS_BY_ID, {
          paymentId: this.paymentId
        })
      }
    }
  },
  methods: {
    async getPrice(params) {
      const dateTime = DateTime.fromISO(params.date).set({
        hour: params.time.split(':')[0],
        minute: params.time.split(':')[1]
      })
      this.priceLoading = true
      try {
        const resp = await api.post(
          `/commercial_location/${this.selectedLocation.id}/price`,
          {
            params: { dateTime, ...params }
          }
        )
        if (resp.status === 200) {
          this.discount = resp.data.discount
          this.amountBeforeDiscount = resp.data.amountBeforeDiscount / 100
          this.calculatedPrice = resp.data.amount / 100
        }
      } catch (e) {
        this.calculatedPrice = undefined
      }
      this.priceLoading = false
    },
    isInTimeFrame(date, time, timeFrame) {
      const d = DateTime.fromISO(date)
      const t = DateTime.fromISO(time)
      const dateTime = d.set({ hour: t.hour, minute: t.minute })
      const startDateTime = DateTime.fromFormat(timeFrame.start, 'TT').set({
        day: d.day,
        month: d.month,
        year: d.year
      })
      const endDateTime = DateTime.fromFormat(timeFrame.end, 'TT').set({
        day: d.day,
        month: d.month,
        year: d.year
      })
      const interval = Interval.fromDateTimes(startDateTime, endDateTime)
      return interval.contains(dateTime)
    },
    async bookLocation() {
      this.showReview = false
      const d = DateTime.fromISO(this.booking.date)
      const t = DateTime.fromISO(this.booking.time)
      const startDateTime = d.set({ hour: t.hour, minute: t.minute })
      const endDateTime = startDateTime.plus({ minutes: this.booking.duration })
      const bookingObj = {
        startDateTime: startDateTime.toISO(),
        endDateTime: endDateTime.toISO(),
        commercial_location_id: this.selectedLocation.id,
        booking_params: this.priceParams.reduce(
          (res, p) => ({
            ...res,
            [p.key]: this.booking[p.key]
          }),
          { duration: this.booking.duration }
        ),
        use_creditcard: this.activeCardOnLocation?.remaining > 0
      }
      const resp = await this.$store.dispatch(
        LOCATION_BOOK_COMMERCIAL,
        bookingObj
      )
      // if (resp?.redirect_url) {
      //   window.location.href = resp.redirect_url
      if (resp) {
        this.showConfirmation = true
      } else {
        this.showError = true
      }
    },
    backToOverview() {
      this.$router.push('/locations')
    },
    setParam(type, val) {
      if (type === 'date') {
        this.booking = {
          date: val
        }
        this.$store.dispatch(LOCATION_GET_COMMERCIAL_AVAILABILITY, {
          locationId: this.selectedLocation.id,
          date: DateTime.fromISO(val).toISODate()
        })
      } else {
        this.booking = {
          ...this.booking,
          [type]: val
        }
      }
    },
    isValid() {
      const regex = RegExp(
        /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
      )
      return regex.test(this.phoneNumber)
    },
    storePhoneNumber() {
      setTimeout(() => {
        if (this.isValid()) {
          this.phoneNumberError = false
          this.$store.dispatch(PARTICIPANT_UPDATE_PHONENUMBER, {
            phoneNumber: this.phoneNumber
          })
        } else {
          this.phoneNumberError = true
        }
      }, 500)
    },
    getTime(dateTime) {
      return DateTime.fromISO(dateTime).toFormat('HH:mm')
    }
  }
}
</script>
