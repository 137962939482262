<template>
  <div class="has-tab-bar">
    <AppBar title="Locatie geboekt" :show-back-button="false" />
    <SpinnerLoader v-if="loading" />
    <div class="container">
      <h1>Boeking verstuurd</h1>
      <div class="alert alert-invi mt-3 mb-5 text-sm">
        Je ontvangt een e-mail met de bevestiging van je boeking en een link om
        te betalen. Dit kan even duren (max 4 uur). Na 4 uur niets ontvangen?
        Check dan ook even je spamfolder of neem contact op via
        contact@inviplay.nl
        <br />
        <br />
        Let op: Je reservering is pas definitief als je betaald hebt.
      </div>
      <!-- <div>
        Wil je een activiteit aanmaken voor deze boeking en mensen uitnodigen?
      </div>
      <div class="form-group mt-5">
        <FormCheckbox label="" checkbox-position="left">
          Laat Inviplay ook mensen uit mijn buurt uitnodigen
        </FormCheckbox>
      </div> -->
      <button class="button button-primary w-full mt-10" @click="roundup">
        Afronden
      </button>
    </div>
    <TabBar selected="" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'
import { EVENT_CREATE_SUCCESS } from '@/store/actions/event'

export default {
  name: 'LocationBookingToActivity',
  components: { AppBar, SpinnerLoader, TabBar },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      loading: (state) => state.group.loading
    })
  },
  watch: {},
  created: function () {},
  mounted: function () {},
  methods: {
    roundup() {
      this.$store.commit(EVENT_CREATE_SUCCESS)
      this.$router.push('/locations')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
